import createPonyfill from 'web-speech-cognitive-services/lib/SpeechServices';
import DictateButton from 'react-dictate-button';
import { useEffect, useState } from 'react';
import classNames from './classNames';
import { generateAuthorizationToken } from './apiClient';

const DictateElement = (props) => {

    const [ponyfill, setPonyfill] = useState(null);
    const [authorizationToken, setAuthorizationToken] = useState(null);

    useEffect(
        () => {
            async function _generateAuthorizationToken() {
                await generateAuthorizationToken()
                    .then((_authorizationToken) => setAuthorizationToken(_authorizationToken));
            }
            void _generateAuthorizationToken();
        },
        [setAuthorizationToken]
    );

    useEffect(
        () => {
            if (!ponyfill && authorizationToken) {
                async function _createPonyfill() {
                    await setPonyfill(createPonyfill({
                        credentials: {
                            region: 'westeurope',
                            authorizationToken: authorizationToken
                        }
                    }));
                }
                void _createPonyfill();
            }
        },
        [authorizationToken, setPonyfill, ponyfill]
    );

    return <>
        {
            (ponyfill !== null)
            ?
                <DictateButton
                    onDictate={ ({ result }) => (result !== undefined && result.hasOwnProperty('transcript')) ? props.onDictate(result.transcript) : '' }
                    onProgress={ (e) => props.onProgress(e) }
                    onClick={ (e) => props.onClick(e) }
                    className={classNames.buttonClassNames}
                    lang={props.lang}
                    ponyfill={ponyfill}
                >
                    {props.ctaText}
                </DictateButton>
            :
                <>initializing...</>
        }
    </>
};

export default DictateElement;
