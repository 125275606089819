import React, {useEffect, useState} from 'react';
import SayElement from './SayElement';
import DictateElement from './DictateElement';
import classNames from './classNames';
import {
    getAssistantDialogEntryFollowingUserDialogEntry,
    getTransTexts,
    handleNewUserDialogEntryText
} from './apiClient';
import ReactMarkdown from 'react-markdown';

function TextWithLineBreaks(props) {
    return props.text.split('\n').map((line, index) =>
        <React.Fragment key={index}> {line} <br/> </React.Fragment>
    );
}

const StartElement = () => {

    const [lang, setLang] = useState(navigator.language === 'de-DE' ? 'de-DE' : 'en-US');

    const [transTexts, setTransTexts] = useState(null);

    const [sessionIsStarted, setSessionIsStarted] = useState(false);
    const [sessionId, setSessionId] = useState(null);

    const [userDialogTexts, setUserDialogTexts] = useState([]);
    const [assistantDialogEntries, setAssistantDialogEntries] = useState([]);
    const [dialogEntries, setDialogEntries] = useState([]);

    const [talkOnlyDictateTexts, setTalkOnlyDictateTexts] = useState([]);
    const [talkOnlyDictateProgressTexts, setTalkOnlyDictateProgressTexts] = useState([]);

    const [sayTexts, setSayTexts] = useState([]);

    const [immediatelySendTalkOnlyDictate, setImmediatelySendTalkOnlyDictate] = useState(false);

    const [talkOnlyDictateIsRunning, setTalkOnlyDictateIsRunning] = useState(false);
    const [talkOnlyDictateElementIsListening, setTalkOnlyDictateElementIsListening] = useState(false);
    const [waitingForAssistantResponse, setWaitingForAssistantResponse] = useState(false);

    const [isInTypingMode, setIsInTypingMode] = useState(false);
    const [typingModeText, setTypingModeText] = useState('');
    const [typingModeCursorPosition, setTypingModeCursorPosition] = useState(0);
    const [typingModeTextAtStartOfDictate, setTypingModeTextAtStartOfDictate] = useState('');
    const [typingModeDictateIsRunning, setTypingModeDictateIsRunning] = useState(false);



    const handleTalkOnlyDictateHasStarted = () => {
        setTalkOnlyDictateIsRunning(true);
        setTalkOnlyDictateElementIsListening(true);
        setSayTexts([]);
    };

    const handleTalkOnlyDictateProgress = (progressData) => {
        if (progressData.results) {
            setTalkOnlyDictateProgressTexts(
                progressData.results.map((result) => result.transcript)
            );
        }
    };

    const handleTalkOnlyDictateElementHasStoppedListening = (text) => {
        setTalkOnlyDictateElementIsListening(false);
        setTalkOnlyDictateTexts([...talkOnlyDictateTexts, talkOnlyDictateProgressTexts]);
        setTalkOnlyDictateProgressTexts([]);

        if (immediatelySendTalkOnlyDictate) {
            setTalkOnlyDictateIsRunning(false);
            setTalkOnlyDictateProgressTexts([]);
            setTalkOnlyDictateTexts([]);
            setUserDialogTexts([
                ...userDialogTexts,
                text.charAt(0).toUpperCase() + text.slice(1)
            ]);
            submitTextToAssistant(text);
        }
    };

    const handleUserWantsToSubmitTalkOnlyDictate = () => {
        const text = talkOnlyDictateTexts.join(' ');
        setTalkOnlyDictateIsRunning(false);
        setTalkOnlyDictateProgressTexts([]);
        setTalkOnlyDictateTexts([]);
        setUserDialogTexts([
            ...userDialogTexts,
            text.charAt(0).toUpperCase() + text.slice(1)
        ]);
        submitTextToAssistant(text);
    }

    const handleTypingModeDictateHasStarted = () => {
        setTypingModeTextAtStartOfDictate(typingModeText);
        setTypingModeDictateIsRunning(true);
        setSayTexts([]);
    };

    const handleTypingModeDictateProgress = (progressData) => {
        if (progressData.results) {
            setTypingModeText([
                typingModeTextAtStartOfDictate.slice(0, typingModeCursorPosition),
                ' ' + progressData.results.map((result) => result.transcript).join(' ') + ' ',
                typingModeTextAtStartOfDictate.slice(typingModeCursorPosition)].join('')
            );
        }
    };

    const handleTypingModeDictateHasFinished = () => {
        setTypingModeDictateIsRunning(false);
        setTypingModeCursorPosition(9999999);
    };

    const handleTypingModeTextSubmitted = () => {
        const text = typingModeText;
        setTypingModeText('');
        setIsInTypingMode(false);

        setUserDialogTexts([
            ...userDialogTexts,
            text
        ]);

        submitTextToAssistant(text);
    };


    const submitTextToAssistant = (text) => {
        setWaitingForAssistantResponse(true);

        handleNewUserDialogEntryText(
            sessionId,
            text
        ).then((data) => {
            setTimeout(
                () => {
                    checkForAssistantDialogEntryFollowingUserDialogEntry(data);
                },
                2000
            );
        });
    };

    const checkForAssistantDialogEntryFollowingUserDialogEntry = (userDialogEntryId, retries = 0) => {
        if (retries > 30) {
            setSayTexts([...sayTexts, transTexts[lang]['dictateWaitTimedOut']]);
            setAssistantDialogEntries([
                ...assistantDialogEntries,
                { dialogEntryType: 'assistant', message: transTexts[lang]['dictateWaitTimedOut'], url: null }
            ]);
            setWaitingForAssistantResponse(false);
            return;
        }

        getAssistantDialogEntryFollowingUserDialogEntry(
            userDialogEntryId
        ).then((data) => {
            if (!data.hasOwnProperty('dialogEntry')) {
                setTimeout(
                    () => {
                        checkForAssistantDialogEntryFollowingUserDialogEntry(
                            userDialogEntryId,
                            retries + 1
                        );
                    },
                    2000
                );
            } else {
                setAssistantDialogEntries([...assistantDialogEntries, data['dialogEntry']]);
                if (data['dialogEntry']['dialogEntryType'] !== 'error') {
                    setSayTexts([...sayTexts, data['say']]);
                }
                setWaitingForAssistantResponse(false);
            }
        });
    };


    useEffect(
        () => {
            async function _getServerTransTexts() {
                await getTransTexts()
                    .then((_transTexts) => setTransTexts(_transTexts));
            }
            void _getServerTransTexts();
        },
        []
    );

    useEffect(
        () => {
            if (lang && transTexts) {
                setSayTexts([transTexts[lang]['initialAssistantGreeting']]);

                setAssistantDialogEntries([{
                    message: transTexts[lang]['initialAssistantGreeting'],
                    dialogEntryType: 'assistant',
                    url: null
                }]);
            }
        },
        [transTexts, lang]
    );

    useEffect(() => {
        setSessionId(Math.random())
    }, [setSessionId]);

    useEffect(() => {
        const numEntries = Math.max(userDialogTexts.length, assistantDialogEntries.length);

        const _dialogEntries = [];

        for (let i = 0; i < numEntries; i++) {
            if (i < assistantDialogEntries.length) {
                _dialogEntries.push(assistantDialogEntries[i]);
            }
            if (i < userDialogTexts.length) {
                _dialogEntries.push({ dialogEntryType: 'user', message: userDialogTexts[i], url: null });
            }
        }

        setDialogEntries(_dialogEntries);

    }, [userDialogTexts, assistantDialogEntries]);




    document.addEventListener('keydown', function(event) {
        if (   document.activeElement.tagName !== 'INPUT'
            && document.activeElement.tagName !== 'TEXTAREA'
        ) {
            if (event.key === 't' || event.key === 'T') {
                setIsInTypingMode(true);
                event.preventDefault();
            }
        }
    });




    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        return (
            <div className='w-full mt-32 mx-6 flex flex-row justify-center items-center'>
                We are awfully sorry, but this application only works in Chrome and Safari.
            </div>
        );
    }

    if (!transTexts) {
        return <>Loading...</>;
    }

    return (
        <div className='mt-16 flex flex-col justify-center items-center'>
            {
                !sessionIsStarted
                &&
                <>
                    <div className='flex flex-col gap-4 w-full max-w-lg px-6 mb-8'>
                        <div className='font-semibold text-4xl'>
                            {transTexts[lang]['welcome']}
                        </div>

                        <div className='font-extralight text-2xl'>
                            {transTexts[lang]['intro1']}
                        </div>

                        <div className='font-extralight text-2xl mb-8'>
                            {transTexts[lang]['intro2']}
                        </div>

                        <div className='font-semibold text-2xl text-center'>
                            {transTexts[lang]['chooseLanguage']}
                        </div>
                    </div>

                    <div className='flex flex-col gap-4 w-full max-w-xs px-6'>
                        <button
                            onClick={() => {
                                setSessionIsStarted(true);
                                setLang('en-US');
                            }}
                            className={classNames.buttonClassNames}
                        >
                            English
                        </button>

                        <button
                            onClick={() => {
                                setSessionIsStarted(true);
                                setLang('de-DE');
                            }}
                            className={classNames.buttonClassNames}
                        >
                            Deutsch
                        </button>
                    </div>
                </>
            }

            {
                (sessionIsStarted && lang && sessionId)
                &&
                <>

                    {
                        sayTexts.map((text, index) =>
                            <SayElement
                                key={index}
                                text={text}
                                lang={lang}
                            />
                        )
                    }


                    <>
                        <div
                            className={(talkOnlyDictateElementIsListening || waitingForAssistantResponse)
                                ? 'hidden'
                                : 'flex flex-col w-full max-w-lg px-6'
                            }
                        >
                            <DictateElement
                                onDictate={(text) => handleTalkOnlyDictateElementHasStoppedListening(text)}
                                onProgress={(progressData) => handleTalkOnlyDictateProgress(progressData)}
                                onClick={() => handleTalkOnlyDictateHasStarted()}
                                lang={lang}
                                ctaText={transTexts[lang]['dictateCtaText']}
                            />
                        </div>

                        {
                            talkOnlyDictateElementIsListening
                            &&
                            <div className='flex flex-col w-full max-w-lg px-6'>
                                <button
                                    className={`${classNames.buttonClassNames} animate-pulse w-full`}
                                    onClick={() => setTalkOnlyDictateIsRunning(false)}
                                >
                                    {transTexts[lang]['dictateListenInfo']}
                                </button>
                            </div>
                        }

                        {
                            waitingForAssistantResponse
                            &&
                            <div className='flex flex-col w-full max-w-lg px-6'>
                                <button
                                    className={`${classNames.buttonClassNames} w-full animate-pulse`}
                                    disabled={true}
                                >
                                    {transTexts[lang]['dictateWaitInfo']}
                                </button>
                            </div>
                        }

                        <div
                            className={`mt-4 flex flex-row gap-3 justify-center items-center cursor-pointer ${(talkOnlyDictateIsRunning ? 'hidden' : '')}`}
                            onClick={() => setImmediatelySendTalkOnlyDictate(!immediatelySendTalkOnlyDictate)}
                        >
                            {
                                !immediatelySendTalkOnlyDictate
                                &&
                                <div className='text-lime-900'>
                                    {transTexts[lang]['doNotImmediatelySendTalkOnlyDictate']}
                                </div>
                            }
                            {
                                immediatelySendTalkOnlyDictate
                                &&
                                <div className='text-neutral-300'>
                                    {transTexts[lang]['doNotImmediatelySendTalkOnlyDictate']}
                                </div>
                            }
                            <div
                                className="relative inline-flex items-center cursor-pointer"
                            >
                                <input
                                    type="checkbox"
                                    value=""
                                    checked={immediatelySendTalkOnlyDictate}
                                    readOnly={true}
                                    className="sr-only peer"
                                    onClick={() => setImmediatelySendTalkOnlyDictate(!immediatelySendTalkOnlyDictate)}
                                />
                                <div
                                    className="
                                        w-11 h-6 bg-gray-200
                                        border border-lime-700
                                        peer
                                        peer-focus:outline-none peer-focus:ring-0
                                        peer-focus:ring-lime-300
                                        rounded-full
                                        peer-checked:after:translate-x-full
                                        peer-checked:after:border-white
                                        after:content-[''] after:absolute after:top-[2px] after:left-[2px]
                                        after:bg-white after:border-gray-300 after:border after:rounded-full
                                        after:h-5 after:w-5 after:transition-all
                                        peer-checked:bg-lime-300
                                    "
                                ></div>
                            </div>
                            {
                                immediatelySendTalkOnlyDictate
                                &&
                                <div className='text-lime-900'>
                                    {transTexts[lang]['doImmediatelySendTalkOnlyDictate']}
                                </div>
                            }
                            {
                                !immediatelySendTalkOnlyDictate
                                &&
                                <div className='text-neutral-300'>
                                    {transTexts[lang]['doImmediatelySendTalkOnlyDictate']}
                                </div>
                            }
                        </div>
                    </>


                    <div className='w-full max-w-2xl flex flex-col gap-4 justify-start items-start mt-16 mb-16 px-8'>

                        {
                            talkOnlyDictateIsRunning
                            &&
                            <div
                                className={`
                                            w-full flex flex-row gap-2 justify-start items-start
                                            py-4 px-6 rounded-lg
                                            ml-6 text-lime-900 rounded-bl-none bg-lime-200 border border-lime-400
                                        `}
                            >
                                <div className='w-full'>
                                    <div className='inline'>
                                        {talkOnlyDictateTexts.map((text, index) => <span key={index}>{text} </span>)}
                                        &nbsp;
                                        {talkOnlyDictateProgressTexts.map((text, index) => <span key={index}>{text}</span>)}
                                    </div>
                                    {
                                        talkOnlyDictateElementIsListening
                                        &&
                                        <>
                                            ...
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="1.5" stroke="currentColor" className="ml-1 w-5 h-5 animate-spin-slow inline">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M9 9.563C9 9.252 9.252 9 9.563 9h4.874c.311 0 .563.252.563.563v4.874c0 .311-.252.563-.563.563H9.564A.562.562 0 019 14.437V9.564z"/>
                                            </svg>
                                        </>
                                    }

                                    {
                                        (!immediatelySendTalkOnlyDictate && !talkOnlyDictateElementIsListening && talkOnlyDictateTexts.length > 0)
                                        &&
                                            <div className='mt-4 w-full flex flex-row justify-end gap-2'>
                                                <button
                                                    className={classNames.smallButtonClassNames}
                                                    onClick={() => setTalkOnlyDictateTexts([])}
                                                >
                                                    {transTexts[lang]['talkOnlyDictateResetCta']}
                                                </button>
                                                <button
                                                    className={classNames.smallButtonClassNames}
                                                    onClick={() => handleUserWantsToSubmitTalkOnlyDictate()}
                                                >
                                                    {transTexts[lang]['talkOnlyDictateSubmitCta']}
                                                </button>
                                            </div>
                                    }
                                </div>
                            </div>
                        }

                        {
                            waitingForAssistantResponse
                            &&
                            <div
                                className={`
                                            w-full flex flex-row gap-2 justify-start items-center
                                            py-4 px-6 rounded-lg
                                            font-normal mr-6 text-blue-900 text-left rounded-br-none bg-blue-50 border border-blue-200
                                        `}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 animate-spin-slow">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"/>
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                </svg>

                                <span className='animate-pulse'>{transTexts[lang]['dictateWaitInfo']}</span>
                            </div>
                        }

                        {
                            (!talkOnlyDictateIsRunning && !waitingForAssistantResponse && !isInTypingMode)
                            &&
                            <div className='w-full flex flex-row justify-end'>
                                <button
                                    className='flex flex-flow gap-2 justify-start items-center py-1 px-3 border border-neutral-200 bg-neutral-100 rounded-md opacity-50 hover:opacity-100'
                                    onClick={() => setIsInTypingMode(true)}
                                    title={'Shortcut: t'}
                                >
                                    <span className='text-xs text-black font-normal'>{transTexts[lang]['typingModeActivateCta']}</span>
                                    <svg
                                        className='h-4 mt-0.5 opacity-80'
                                        version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000"
                                    >
                                        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                                        <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M5891.8,4113.1c-283.4-143-303.8-500.4-40.8-694.4c61.3-48.5,112.3-53.6,451.9-61.3l380.4-7.7V107.4v-3239.8h-367.6c-337,0-372.7-5.1-449.3-56.2c-127.6-86.8-183.8-181.3-196.6-319.1c-12.8-165.9,66.4-306.4,222.1-382.9c112.3-58.7,122.5-58.7,1199.9-58.7c1077.4,0,1087.6,0,1199.9,58.7c163.4,81.7,217,168.5,217,349.8c0,168.5-45.9,252.8-191.5,349.8c-76.6,53.6-112.3,58.7-449.3,58.7h-367.6V110v3242.3h367.6c337,0,372.7,5.1,449.3,58.7c145.5,97,191.5,181.3,191.5,349.8c0,181.3-53.6,268.1-217,352.3c-112.3,56.2-122.5,56.2-1199.9,56.2C6014.3,4169.3,6004.1,4169.3,5891.8,4113.1z"/><path d="M369.6,2318.3c-130.2-45.9-217-130.2-250.2-240c-15.3-61.3-23-748-17.9-2024.5c7.7-1917.3,7.7-1932.6,61.3-2004.1c30.6-38.3,91.9-94.5,140.4-122.5c84.2-51.1,137.8-51.1,3043.2-58.7l2953.8-5.1v408.5v408.5H3606.8H913.4V110v1429.7h2693.4h2693.4v408.5v408.5H3384.7C979.8,2354.1,448.8,2349,369.6,2318.3z"/><path d="M7883.1,1948.2v-408.5h600h600V110v-1429.7h-600h-600v-408.5v-411l862.9,7.7c845.1,7.7,862.9,7.7,954.8,66.4c53.6,33.2,114.9,102.1,145.5,168.5C9900-1789.4,9900-1758.8,9900,110c0,1868.8,0,1899.4-53.6,2006.7c-30.6,66.4-91.9,135.3-145.5,168.5c-91.9,58.7-109.8,58.7-954.8,66.4l-862.9,7.7V1948.2z"/><path d="M2376.3,1156.7c-68.9-28.1-91.9-74-541.2-1067.1c-211.9-469.8-360-829.7-360-875.7c0-112.3,120-227.2,237.4-227.2c120,0,206.8,76.6,275.7,242.5l58.7,140.4h441.7h444.2l74-163.4c40.9-91.9,97-176.2,125.1-191.5c63.8-35.7,196.6-35.7,260.4-2.5c56.1,30.6,125.1,153.2,125.1,222.1c0,89.4-788.9,1830.5-857.8,1891.8C2593.3,1184.8,2478.4,1197.6,2376.3,1156.7z M2634.2,115.1c63.8-158.3,117.4-293.6,117.4-298.7c0-7.7-114.9-12.8-255.3-12.8c-140.4,0-255.3,5.1-255.3,10.2c0,12.8,227.2,551.4,245.1,582.1C2506.5,429.1,2501.4,439.3,2634.2,115.1z"/></g></g>
                                    </svg>
                                </button>
                            </div>
                        }

                        {
                            (!talkOnlyDictateIsRunning && !waitingForAssistantResponse && isInTypingMode)
                            &&
                            <div className='w-full flex flex-col justify-center items-end mb-8'>
                                <textarea
                                    className='w-full rounded-md bg-lime-50 text-lime-900 p-8 border border-lime-400 mb-4'
                                    rows="10"
                                    cols="20"
                                    value={typingModeText}
                                    autoFocus={true}
                                    placeholder={transTexts[lang]['typingModeTextPlaceholder']}

                                    onChange={(e) => setTypingModeText(e.target.value)}
                                    onMouseUp={(e) => setTypingModeCursorPosition(e.target.selectionStart)}
                                    onKeyUp={(e) => setTypingModeCursorPosition(e.target.selectionStart)}
                                    onKeyDown={(e) => e.ctrlKey && e.key === 'Enter' && handleTypingModeTextSubmitted()}
                                ></textarea>

                                <div className='flex flex-row gap-4 justify-end items-center'>

                                    <div className={typingModeDictateIsRunning ? 'hidden' : ''}>
                                        <DictateElement
                                            onDictate={(text) => handleTypingModeDictateHasFinished(text)}
                                            onProgress={(progressData) => handleTypingModeDictateProgress(progressData)}
                                            onClick={() => handleTypingModeDictateHasStarted()}
                                            lang={lang}
                                            ctaText={transTexts[lang]['typingModeTextDictateCta']}
                                        />
                                    </div>
                                    {
                                        typingModeDictateIsRunning
                                        &&
                                        transTexts[lang]['typingModeTextDictateListenInfo']
                                    }

                                    <button
                                        className={classNames.buttonClassNames}
                                        onClick={() => handleTypingModeTextSubmitted()}
                                        title={'Shortcut: CTRL+ENTER'}
                                    >
                                        {transTexts[lang]['typingModeTextSubmitCta']}
                                    </button>
                                </div>
                            </div>
                        }

                        {/* The dialog entries */}
                        {
                            dialogEntries.slice().reverse().map((dialogEntry, index) =>
                                <div
                                    key={index}
                                    className={`
                                        dialog-entry-container
                                        overflow-hidden hover:overflow-x-scroll
                                        w-full flex flex-col justify-center
                                        py-4 px-6 rounded-lg 
                                        ${dialogEntry.dialogEntryType === 'user'
                                            ? 'ml-6 text-lime-900 items-start text-left rounded-bl-none bg-lime-200 border border-lime-400'
                                            : dialogEntry.dialogEntryType === 'error'
                                                ? 'font-normal mr-6 text-red-900 items-start text-left rounded-tr-none bg-red-50 border border-red-200'
                                                : 'font-normal mr-6 text-blue-900 items-start text-left rounded-tr-none bg-blue-50 border border-blue-200'
                                        }
                                    `}
                                >
                                    {
                                        (dialogEntry.dialogEntryType === 'assistant' && index === 0)
                                        &&
                                        <div className='w-full text-right mb-4'>
                                            <button
                                                className='text-xs font-normal text-blue-300 bg-blue-100 rounded-md p-1 px-2 hover:bg-blue-200 hover:text-blue-400'
                                                onClick={() => setSayTexts([])}
                                            >
                                                {transTexts[lang]['stopAudioOutput']}
                                            </button>
                                        </div>
                                    }

                                    {
                                        (dialogEntry.dialogEntryType === 'assistant'
                                            || dialogEntry.dialogEntryType === 'error')
                                        &&
                                        <ReactMarkdown>{dialogEntry.message}</ReactMarkdown>
                                    }

                                    {
                                        dialogEntry.dialogEntryType === 'user'
                                        &&
                                        <TextWithLineBreaks text={dialogEntry.message}/>
                                    }

                                    {
                                        (dialogEntry.hasOwnProperty('url') && dialogEntry.url)
                                        &&
                                        <div className={`w-full mt-8 mb-4 ${index === 0 ? 'animate-pulse' : ''}`}>
                                            <a
                                                className={`${classNames.buttonClassNames}`}
                                                href={dialogEntry.url}
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                {transTexts[lang]['ctaClickLinkInMessage']}
                                            </a>
                                        </div>
                                    }
                                </div>
                            )
                        }
                    </div>
                </>
            }
        </div>
    );
};

export default StartElement;
