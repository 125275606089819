import createPonyfill from 'web-speech-cognitive-services/lib/SpeechServices';
import React, { useEffect, useState } from 'react';
import Say from 'react-say';
import { generateAuthorizationToken } from './apiClient';

const SayElement = (props) => {
    const [ponyfill, setPonyfill] = useState(null);
    const [voices, setVoices] = useState(null);
    const [authorizationToken, setAuthorizationToken] = useState(null);

    useEffect(
        () => {
            async function _generateAuthorizationToken() {
                await generateAuthorizationToken()
                    .then((_authorizationToken) => setAuthorizationToken(_authorizationToken));
            }
            void _generateAuthorizationToken();
        },
        [setAuthorizationToken]
    );

    useEffect(
        () => {
            if (!ponyfill && authorizationToken) {
                async function _createPonyfill() {
                    await setPonyfill(createPonyfill({
                        credentials: {
                            region: 'westeurope',
                            authorizationToken: authorizationToken
                        }
                    }));
                }
                void _createPonyfill();
            }
        },
        [authorizationToken, setPonyfill, ponyfill]
    );

    useEffect(() => {
        if (ponyfill) {
            ponyfill.speechSynthesis.addEventListener('voiceschanged', () => {
                const _voices = ponyfill.speechSynthesis.getVoices();

                const regex = new RegExp(props.lang, 'u');
                setVoices(_voices.find(voice => regex.test(voice.name)));
            });
        }
    }, [props.lang, ponyfill, voices, setVoices]);

    console.debug(props
        .text
        .replace('&', ' ')
        .replace(/</g, '')
        .replace(/>/g, '')
        .replace(/```[\s\S]*?```/g, ''));

    return (
        (ponyfill && voices)
        &&
            <Say
                ponyfill={ponyfill}
                text={
                    props
                        .text
                        .replace('&', ' ')
                        .replace(/</g, '')
                        .replace(/>/g, '')
                        .replace(/```[\s\S]*?```/g, '')
                }
                lang={props.lang}
                voice={voices}
            />
    );
};

export default SayElement;
