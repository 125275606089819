const dialogContext = (new URLSearchParams(window.location.search)).get('dialogContext');

export function getTransTexts() {
    return fetch(
        `/api/trans-texts/?dialogContext=${dialogContext}`,
        {
            method: 'GET',
            body: null
        }
    ).then(data => data.json());
}

export function generateAuthorizationToken() {
    return fetch(
        `/api/azure-cognitive-services-authorization-token/?dialogContext=${dialogContext}`,
        {
            method: 'POST',
            body: null
        }
    ).then(data => data.json());
}

export function handleNewUserDialogEntryText(sessionId, userDialogEntryText) {
    return fetch(
        `/api/user-dialog-entry-texts/?dialogContext=${dialogContext}`,
        {
            method: 'POST',
            body: JSON.stringify({sessionId, userDialogEntryText})
        }
    ).then(data => data.json());
}

export function getAssistantDialogEntryFollowingUserDialogEntry(userDialogEntryId) {
    return fetch(
        `/api/assistant-dialog-entries/?userDialogEntryId=${userDialogEntryId}`,
        {
            method: 'GET',
            body: null
        }
    ).then(data => data.json());
}
