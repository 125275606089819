import './App.css';
import StartElement from "./StartElement";

function App() {
    return (
        <div>
            <StartElement/>
        </div>
    );
}

export default App;
